import '../styles/main.scss';
// import { Link } from 'react-router-dom';

function Main() {
  return (
    <div className="main">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <article className="content">
        <div className='wrapper wrapper-bottom wrapper-right'>
          <h2>...something wicked this way comes...</h2>
        </div>
        <div className='wrapper wrapper-bottom wrapper-left block-text'>
          <h1>Alex Howes</h1>
        </div>
        <div className='wrapper wrapper-bottom wrapper-right block-text'>
          <p>sponsor announcement and site reveal coming late winter</p>
        </div>
        <div className='wrapper wrapper-bottom wrapper-left block-text'>
          <a className='main__contact-link' href='mailto: contactalexhowes@gmail.com'>contact Alex</a>
        </div>
        <div className='wrapper wrapper-right side-rule'></div>
      </article>
    </div>
  );
}

export default Main;
