import { useRouteError } from "react-router-dom";
import { Link } from 'react-router-dom';

const NotFound = () => {
  const error = useRouteError();
  console.log(error);
  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, we can't find that page.</p>
      <p>{error.statusText || error.message}</p>
      <Link to={`/`}>Go back home</Link>
    </div>
  );
}

export default NotFound;
